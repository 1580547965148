import { AbilityBuilder, createMongoAbility } from '@casl/ability';
export const getUserAbilities = (role) => {
  const { can, build } = new AbilityBuilder(createMongoAbility);
  if (role == 'admin') {
    can('manage', 'all');
  } else if (role == 'user') {
    can('manage', 'client');
  }
  return build();
};
