const sessionsColumnData = [
  {
    Header: 'Id',
    accessor: 'id'
  },
  {
    Header: 'Player username',
    accessor: 'player'
  },
  {
    Header: 'Game',
    accessor: 'game'
  },
  {
    Header: 'Bet range',
    accessor: 'betRange'
  },
  {
    Header: 'Duration',
    accessor: 'duration'
  },
  {
    Header: 'IP',
    accessor: 'ip'
  }
];
export default sessionsColumnData;
