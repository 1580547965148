import useAxios from 'hooks/useAxios';

export const getMetrics = async (limit = 20, page = 1, filters = '') => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/metric', {
      params: {
        limit: limit,
        page: page
      }
    });
    return {
      data: unformattedData.data.data.map((x) => {
        return {
          ...x,
          player: x.session.player.username,
          game: x.session.game.game,
          betRange: x.session.betRange,
          currency: x.session.player.currency,
          client: x.session.player.client.name
        };
      }),
      meta: unformattedData.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};
