const metricColumnData = [
  {
    Header: 'Metric type',
    accessor: 'metricType'
  },
  {
    Header: 'Amount',
    accessor: 'amount'
  },
  {
    Header: 'Metric date',
    accessor: 'createdAt'
  },
  {
    Header: 'Player',
    accessor: 'player'
  },
  {
    Header: 'Game',
    accessor: 'game'
  },
  {
    Header: 'Bet range',
    accessor: 'betRange'
  },
  {
    Header: 'Currency',
    accessor: 'currency'
  },
  {
    Header: 'Client',
    accessor: 'client'
  }
];
export default metricColumnData;
